export function highlightText(
  input: string, 
  keyword: string,
  replace: (match: string) => string = (match) => `<strong class="txt_key">${match}</strong>`
) {
  if (!keyword || !input) return input; // 검색어나 입력 문자열이 없는 경우 원본 반환

  // 특수 문자 이스케이프 처리
  const escapeRegExp = (text: string) => text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  // 검색어를 정규식으로 변환 (대소문자 구분 없음)
  const regex = new RegExp(escapeRegExp(keyword), 'gi');

  // 문자열 내 검색어를 강조 태그로 감싸기
  return input.replace(regex, replace);
}
