import type { JSX } from 'react';

import {
  AudioFileIcon,
  DefaultFileIcon,
  DocFileIcon,
  FigFileIcon,
  HwpFileIcon,
  ImgFileIcon,
  PdfFileIcon,
  PptFileIcon,
  SourceFileIcon,
  TxtFileIcon,
  VideoFileIcon,
  XlsxFileIcon,
  ZipFileIcon,
} from '@dop-ui/icons/react/dop/16';
import { IconCommonProps } from '@dop-ui/icons/react/dop/types';
type FileType =
  | 'pdf'
  | 'ppt'
  | 'doc'
  | 'excel'
  | 'zip'
  | 'audio'
  | 'video'
  | 'hwp'
  | 'txt'
  | 'html'
  | 'fig'
  | 'image';

export function getFileIcon(
  fileType: string,
): (props: IconCommonProps) => JSX.Element {
  const type = (fileType as FileType).toLowerCase();
  const parsedType = extensionMap.get(type);

  switch (parsedType) {
    case 'doc':
      return DocFileIcon;
    case 'zip':
      return ZipFileIcon;
    case 'ppt':
      return PptFileIcon;
    case 'excel':
      return XlsxFileIcon;
    case 'hwp':
      return HwpFileIcon;
    case 'pdf':
      return PdfFileIcon;
    case 'txt':
      return TxtFileIcon;
    case 'image':
      return ImgFileIcon;
    case 'video':
      return VideoFileIcon;
    case 'audio':
      return AudioFileIcon;
    case 'html':
      return SourceFileIcon;
    case 'fig':
      return FigFileIcon;
    default:
      return DefaultFileIcon;
  }
}

export const extensionMap = new Map<string, string>([
  ['ppt', 'ppt'],
  ['pptx', 'ppt'],
  ['pps', 'ppt'],
  ['ppsx', 'ppt'],
  ['pot', 'ppt'],
  ['potx', 'ppt'],
  ['ppa', 'ppt'],
  ['ppam', 'ppt'],
  ['ppsm', 'ppt'],
  ['pptm', 'ppt'],
  ['xls', 'excel'],
  ['xlsx', 'excel'],
  ['xlsm', 'excel'],
  ['xltx', 'excel'],
  ['xltm', 'excel'],
  ['xlsb', 'excel'],
  ['xlam', 'excel'],
  ['xll', 'excel'],
  ['xlw', 'excel'],
  ['csv', 'excel'],
  ['xla', 'excel'],
  ['xlt', 'excel'],
  ['xml', 'excel'],
  ['xps', 'excel'],
  ['excel', 'excel'],
  ['doc', 'doc'],
  ['docx', 'doc'],
  ['docm', 'doc'],
  ['dot', 'doc'],
  ['dotx', 'doc'],
  ['dotm', 'doc'],
  ['docb', 'doc'],
  ['word', 'doc'],
  ['zip', 'zip'],
  ['rar', 'zip'],
  ['7z', 'zip'],
  ['tar', 'zip'],
  ['egg', 'zip'],
  ['alz', 'zip'],
  ['cab', 'zip'],
  ['ace', 'zip'],
  ['mp4', 'video'],
  ['avi', 'video'],
  ['mov', 'video'],
  ['wmv', 'video'],
  ['flv', 'video'],
  ['mkv', 'video'],
  ['webm', 'video'],
  ['3gp', 'video'],
  ['3g2', 'video'],
  ['m4v', 'video'],
  ['mpg', 'video'],
  ['mpeg', 'video'],
  ['asf', 'video'],
  ['asx', 'video'],
  ['swf', 'video'],
  ['video', 'video'],
  ['mp3', 'audio'],
  ['wav', 'audio'],
  ['flac', 'audio'],
  ['ogg', 'audio'],
  ['m4a', 'audio'],
  ['wma', 'audio'],
  ['aac', 'audio'],
  ['aiff', 'audio'],
  ['ape', 'audio'],
  ['au', 'audio'],
  ['mka', 'audio'],
  ['mid', 'audio'],
  ['midi', 'audio'],
  ['mpa', 'audio'],
  ['ra', 'audio'],
  ['tta', 'audio'],
  ['wv', 'audio'],
  ['ac3', 'audio'],
  ['audio', 'audio'],
  ['jpg', 'image'],
  ['jpeg', 'image'],
  ['gif', 'image'],
  ['bmp', 'image'],
  ['webp', 'image'],
  ['svg', 'image'],
  ['ico', 'image'],
  ['png', 'image'],
  ['image', 'image'],
  ['pdf', 'pdf'],
  ['hwp', 'hwp'],
  ['txt', 'txt'],
  ['html', 'html'],
  ['fig', 'fig'],
]);
